import { type LoaderFunctionArgs, type MetaFunction } from '@remix-run/node';
import { defer, Link, useLoaderData } from '@remix-run/react';
import pluralize from 'pluralize';
import { Suspense } from 'react';

import { MediaType } from '@lp-lib/media';

import placeholder from '../../src/assets/img/placeholder/game-cover.png';
import {
  GameCoverClip,
  GamePackCoverPres,
  type GamePackCoverSize,
} from '../../src/components/Game/GamePackCoverPres';
import { DefaultLogoIcon } from '../../src/components/icons/LogoIcon';
import { PlayIcon } from '../../src/components/icons/PlayIcon';
import { InvisibleRouteErrorBoundary } from '../../src/Error';
import { SiteBaseURL } from '../../src/services/public';
import type { GamePack } from '../../src/types/game';
import { fromDTOGamePacks } from '../../src/utils/api-dto';
import { makeTitle } from '../../src/utils/common';
import { MediaUtils } from '../../src/utils/media';
import { AwaitTypedDeferred, slugify } from '../../src/utils/router';
import { TimeUtils } from '../../src/utils/time';
import { fetchGamePack } from '../fetches/fetchGamePack';
import { fetchGamePacksFavorites } from '../fetches/fetchGamePacksFavorite';
import { fetchGamePacksFeatured } from '../fetches/fetchGamePacksFeatured';

function unwrap<T>(p: PromiseSettledResult<T>, doThrow = false) {
  if (p.status === 'fulfilled') return p.value;
  else if (p.status === 'rejected' && doThrow) throw p.reason;
  return null;
}

async function fetchGameRecommendations() {
  const [featured, favorites] = await Promise.allSettled([
    fetchGamePacksFeatured(),
    fetchGamePacksFavorites(),
  ]);

  return {
    featured: fromDTOGamePacks(unwrap(featured)?.json.gamePacks ?? []) ?? [],
    favorites: fromDTOGamePacks(unwrap(favorites)?.json.gamePacks ?? []) ?? [],
  };
}

type GameRecommendations = Awaited<ReturnType<typeof fetchGameRecommendations>>;

export const loader = async (action: LoaderFunctionArgs) => {
  // Arguably, this shouldn't be possible, since the route will never be matched
  // if the id is missing, but there's no way to enforce this through the
  // premade Params type, and no way to tie this function to the route
  // definition (boo).
  if (!action.params.gamePackId)
    throw new Response('Not Found', { status: 404 });

  // Allow the url to override the visible gamepack name for purposes of SEO/AB
  // testing.
  const url = new URL(action.request.url);
  const overrideName = url.searchParams.get('pagename');
  const overrideVenueId = url.searchParams.get('venue');

  const pack = await fetchGamePack(action.params.gamePackId);
  if (!pack) throw new Response('Not Found', { status: 404 });
  if (overrideName) pack.name = overrideName;

  const venueBase = overrideVenueId
    ? `/venue/${overrideVenueId}/game`
    : `/my-venue`;

  return defer({
    venuePath: `${venueBase}/${pack.id}`,
    venueBase,
    pack,
    recs: fetchGameRecommendations(),
  });
};

function PlayNowBtn(props: { to: string; className?: string }) {
  return (
    <Link
      className={`btn btn-primary flex items-center gap-3 ${
        props.className ?? ''
      }`}
      to={props.to}
    >
      <PlayIcon className='flex-shrink-0 flex-grow-0 w-4 h-4 fill-current' />
      Play Game
    </Link>
  );
}

const Background = (props: { pack: GamePack }): JSX.Element => {
  // TODO: consider unifying this with GamePackDetailCard#Background

  const mediaURL =
    MediaUtils.PickMediaUrl(props.pack.venueBackground) || placeholder;

  return (
    <div className='absolute top-0 right-0 bottom-0 left-0'>
      {props.pack.venueBackground?.type === MediaType.Video ? (
        <video
          src={mediaURL}
          className='w-full h-full rounded-xl border-b object-cover'
          autoPlay
          muted
          loop
        ></video>
      ) : (
        <img
          src={mediaURL}
          alt='venue background'
          className='w-full h-full rounded-xl object-cover'
        ></img>
      )}

      <div
        className='
          absolute top-0 right-0 bottom-0 left-0
          rounded-xl
          bg-gradient-to-r from-lp-black-001 to-transparent
        '
      ></div>

      <div
        className='
          absolute top-0 right-0 bottom-0 left-0
          rounded-xl
          bg-gradient-to-b from-transparent to-black
        '
      ></div>
    </div>
  );
};

function LogosRow(props: { className: string }) {
  // NOTE: these are copied directly from the WP homepage, with manual
  // cleanup/classname changes to simplify.

  return (
    <div className={`flex flex-wrap justify-center gap-9 ${props.className}`}>
      <img
        src='https://lunapark.com/wp-content/uploads/2022/07/Meta-logo-new.png'
        className='self-center'
        alt='Virtual Team Building Games for Work'
        decoding='async'
        loading='lazy'
        width='119'
        height='27'
      />

      <img
        src='https://lunapark.com/wp-content/uploads/2022/07/Stripe-logo-new.png'
        className='self-center'
        alt='Virtual Team Building Games for Work'
        decoding='async'
        loading='lazy'
        width='94'
        height='45'
      />

      <img
        src='https://lunapark.com/wp-content/uploads/2022/07/Amazon-Logo-new.png'
        className='self-center'
        alt='Virtual Team Building Games for Work'
        decoding='async'
        loading='lazy'
        width='109'
        height='61'
      />

      <img
        src='https://lunapark.com/wp-content/uploads/2022/07/Netflix-logo-new.png'
        className='self-center'
        alt='Virtual Team Building Games for Work'
        decoding='async'
        loading='lazy'
        width='110'
        height='35'
      />

      <img
        src='https://lunapark.com/wp-content/uploads/2022/07/tiktok-logo-new.png'
        className='self-center'
        alt='Virtual Team Building Games for Work'
        decoding='async'
        loading='lazy'
        width='120'
        height='35'
      />

      <img
        src='https://lunapark.com/wp-content/uploads/2022/09/Doordash_Logo_RGB_Red-1.png'
        className='self-center'
        alt='Virtual Team Building Games for Work'
        decoding='async'
        loading='lazy'
        width='145'
        height='37'
      />

      <img
        src='https://lunapark.com/wp-content/uploads/2022/07/Activision-Logo-new.png'
        className='self-center'
        alt='Virtual Team Building Games for Work'
        decoding='async'
        loading='lazy'
        width='114'
        height='31'
      />

      <img
        src='https://lunapark.com/wp-content/uploads/2022/07/Salesforce-logo-new.png'
        className='self-center'
        alt='Virtual Team Building Games for Work'
        decoding='async'
        loading='lazy'
        width='106'
        height='69'
      />
    </div>
  );
}

function MoreOndExperiences(props: {
  className: string;
  venueBase: string;
  recs?: GameRecommendations | null;
}) {
  const featured = props.recs?.featured.slice(0, 4) ?? [];
  const favorites = props.recs?.favorites.slice(0, 5) ?? [];

  return (
    <div className={`${props.className}`}>
      <h2 className='text-2xl font-bold text-white text-center lg:text-left'>
        More On Demand Experiences
      </h2>

      <div className='flex justify-between gap-8 flex-wrap lg:flex-nowrap'>
        {featured.map((pack) => (
          <MiniGamePackCardPres
            className='w-full lg:w-1/5 flex-shrink-0'
            key={pack.id}
            pack={pack}
            coverSize='medium'
          />
        ))}
      </div>

      <div className='px-8 flex flex-col gap-8'>
        {favorites.length && (
          <h3 className='text-xl font-bold text-white text-center lg:text-left'>
            Luna Park Favorites
          </h3>
        )}
        <div className='px-11 lg:px-4 flex justify-between gap-16 lg:gap-8 flex-wrap lg:flex-nowrap'>
          {favorites.map((pack) => (
            <MiniGamePackCardPres
              className='w-full lg:w-1/6 flex-shrink-0'
              key={pack.id}
              pack={pack}
              coverSize='small'
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function VideoExplainer(props: { className: string }) {
  return (
    <div
      className={`
        ${props.className}
        flex flex-col gap-4 items-center
        mx-auto
        text-center font-Montserrat text-white
      `}
    >
      <h2 className='text-5xl font-bold'>
        Luna Park is the World's First Live Hosted Game Show for Teams
      </h2>
      <p className='text-2xl'>
        Luna Park was borne of the believe that social games are the absolute
        best way to connect when you're physically distant. We make work
        relationships more human (and fun). You don't have to choose between
        hundreds of events. Just book Luna Park and watch the magic unfold.
      </p>
      <h3 className='text-2xl font-bold'>Watch a trailer first!</h3>
      <p className='text-2xl'>⬇️</p>
      <video playsInline autoPlay loop muted>
        <source
          src='https://lunapark.com/wp-content/uploads/2022/07/LP-Show-video-new.mp4'
          type='video/mp4'
        />
      </video>
    </div>
  );
}

function Reviews(props: { className: string }) {
  return (
    <div className={`${props.className}`}>
      <img
        src='https://d9hhrg4mnvzow.cloudfront.net/lunapark.com/lp/free-virtual-escape-room-teambuilding/6c5e0ffc-6ba0-4881-8689-98734ce88059-7.png'
        alt=''
        loading='lazy'
        className='mb-4'
        draggable='false'
      />
      <img
        src='https://d9hhrg4mnvzow.cloudfront.net/lunapark.com/lp/free-virtual-escape-room-teambuilding/9ea66d8b-e59e-4e66-a176-5ac96b91fec3-1e70b170-0213-40e2-a2e5-649e8823081f-g-original.png'
        alt=''
        loading='lazy'
        className='mb-4'
        draggable='false'
      />
      <img
        src='https://d9hhrg4mnvzow.cloudfront.net/lunapark.com/lp/free-virtual-escape-room-teambuilding/90e70e3d-f20f-413b-bb1e-84dc9666087b-b126e46b-a689-4b71-ba9a-0f09bef67834-9.png'
        alt=''
        loading='lazy'
        className='mb-4'
        draggable='false'
      />
      <img
        src='https://d9hhrg4mnvzow.cloudfront.net/lunapark.com/lp/free-virtual-escape-room-teambuilding/6b1075df-32a5-4867-8d10-e9cb05f04c35-3d0d2717-7c4a-488c-aadf-379ec807d0ca-e-original.png'
        alt=''
        loading='lazy'
        className='mb-4'
        draggable='false'
      />
      <img
        src='https://d9hhrg4mnvzow.cloudfront.net/lunapark.com/lp/free-virtual-escape-room-teambuilding/b97cd045-4fde-4188-882d-a467968c683f-2.png'
        alt=''
        loading='lazy'
        className='mb-4'
        draggable='false'
      />
      <img
        src='https://d9hhrg4mnvzow.cloudfront.net/lunapark.com/lp/free-virtual-escape-room-teambuilding/fb293700-14dd-4c4c-9423-2d1c681bb920-38041868-eaa5-473d-aed2-c77d3da68e3c-c-original.original.png'
        alt=''
        loading='lazy'
        className='mb-4'
        draggable='false'
      />
      <img
        src='https://d9hhrg4mnvzow.cloudfront.net/lunapark.com/lp/free-virtual-escape-room-teambuilding/77d3b135-dfd0-4126-abc5-fea2e1ea65a1-84877c60-ca52-4d55-879b-527663e772b1-testimonial-section-cards-1.png'
        alt=''
        loading='lazy'
        className='mb-4'
        draggable='false'
      />
      <img
        src='https://d9hhrg4mnvzow.cloudfront.net/lunapark.com/lp/free-virtual-escape-room-teambuilding/45a8d567-4750-4525-af05-773857585f10-gitlab.png'
        alt=''
        loading='lazy'
        className='mb-4'
        draggable='false'
      />
      <img
        src='https://d9hhrg4mnvzow.cloudfront.net/lunapark.com/lp/free-virtual-escape-room-teambuilding/43ea1f4d-4041-4d16-bbc5-8319e53adaca-0958f68b-9c2b-4f10-afee-6cec486bf4c3-a1193798-7889-4192-901c-c00e15e3133c-a-original-original.png'
        alt=''
        loading='lazy'
        className='mb-4'
        draggable='false'
      />
      <img
        src='https://d9hhrg4mnvzow.cloudfront.net/lunapark.com/lp/free-virtual-escape-room-teambuilding/5ff5ce30-9708-4752-a8a5-f0399d8f7a8d-2d302f91-1a08-40cd-af1a-4b1585cb30bf-testimonial-section-cards-2-1.png'
        alt=''
        loading='lazy'
        className='mb-4'
        draggable='false'
      />
    </div>
  );
}

function PackCTADesc(props: {
  className: string;
  venuePath: string;
  pack: GamePack;
}) {
  const gamesCount = props.pack.childrenIds?.length;
  return (
    <div className={`${props.className} text-white`}>
      <PlayNowBtn to={props.venuePath} className='h-12 w-48 px-5 text-xl' />

      <aside className='text-base font-bold'>
        {TimeUtils.DurationFormattedHumanMinutes(
          props.pack.approximateDurationSeconds * 1000,
          'Minutes'
        )}{' '}
        • {gamesCount} {pluralize('Minigames', gamesCount)}
      </aside>
      <p>{props.pack.description}</p>
    </div>
  );
}

function MiniGamePackCardPres(props: {
  pack: GamePack;
  className?: string;
  coverSize?: GamePackCoverSize;
}) {
  // TODO: rectify this with the MiniCard.tsx implementation in GameCenter.
  // Can't use it without pulling in _everything_.

  const { pack } = props;
  const gamesCount = pack.childrenIds?.length ?? 0;

  const url = new SiteBaseURL();
  url.pathname = `/games/${slugify(pack.name)}/${pack.id}`;

  return (
    <div className={`${props.className || ''}`}>
      <GamePackCoverPres
        pack={pack}
        size={props.coverSize || 'medium'}
        hover={
          <Link
            to={url.toString()}
            className='btn w-full h-full flex justify-center items-center'
          >
            View Details
          </Link>
        }
      />
      <div className='mt-2 flex items-center gap-2'>
        <p className='text-white text-base truncate whitespace-nowrap'>
          {pack.name}
        </p>
      </div>
      <div
        className='
          text-secondary
          text-sms lg:text-2xs xl:text-sms
          flex flex-nowrap items-center gap-1
        '
      >
        <span>
          {TimeUtils.DurationFormattedHumanMinutes(
            pack.approximateDurationSeconds * 1000,
            'Minutes'
          )}
        </span>
        <span>·</span>
        <span>
          {gamesCount} {pluralize('Minigames', gamesCount)}
        </span>
      </div>
    </div>
  );
}

function MainPackCTA(props: {
  pack: GamePack;
  venuePath: string;
  className?: string;
}) {
  const { pack, venuePath, className } = props;
  return (
    <div
      className={`flex gap-15 flex-col items-center lg:items-start lg:flex-row ${
        className ?? ''
      }`}
    >
      <div className='w-84 h-48'>
        <GamePackCoverPres pack={pack} size='large' />
      </div>
      <PackCTADesc
        className='w-full xl:w-1/2 flex flex-col justify-center gap-3 items-center lg:items-start text-center lg:text-left'
        pack={pack}
        venuePath={venuePath}
      />
    </div>
  );
}

function PublicGamePackPage() {
  const data = useLoaderData<typeof loader>();
  const { pack, venueBase, venuePath } = data;

  if (!pack) return null;

  return (
    <div className='relative w-full h-full overflow-x-hidden flex flex-col'>
      <GameCoverClip id='game-cover-clip' />
      <nav className='h-15 px-8 py-2.5 bg-black flex justify-between items-center'>
        <Link className={`w-12.5 h-12.5 inline-block`} to={'/home'}>
          <DefaultLogoIcon />
        </Link>

        <div className='h-full flex items-center gap-11'>
          <Link className='text-white uppercase font-bold text-xs' to='/login'>
            Sign in
          </Link>
          <PlayNowBtn to={venuePath} className='h-10 px-5' />
        </div>
      </nav>

      <header className='relative'>
        <Background pack={pack} />
        <div
          className='
            relative
            w-full
            px-12 lg:px-21 py-12 lg:py-24
            flex flex-col gap-19 justify-center
          '
        >
          <div className='flex flex-col gap-10'>
            <h1 className='text-6xl font-semibold text-white'>{pack.name}</h1>
            <MainPackCTA pack={pack} venuePath={venuePath} />
          </div>
        </div>
      </header>
      <div className='flex flex-col gap-24 pb-24'>
        <LogosRow className='px-12 py-15' />
        <Suspense fallback={<p className='min-h-75' />}>
          <AwaitTypedDeferred
            resolve={data.recs}
            errorElement={<InvisibleRouteErrorBoundary />}
          >
            {(recs) => (
              <MoreOndExperiences
                className='
                  px-12 lg:px-21
                  flex flex-col gap-8'
                recs={recs as GameRecommendations}
                venueBase={venueBase}
              />
            )}
          </AwaitTypedDeferred>
        </Suspense>

        <VideoExplainer className='w-full lg:w-2/3 px-12 lg:px-0' />
        <div className={`px-8 lg:px-34`}>
          <h2 className='text-2xl'>Reviews from Leaders Like You</h2>
          <Reviews className='columns-2 md:columns-3 lg:columns-4 gap-x-4' />
        </div>
        <MainPackCTA
          pack={pack}
          venuePath={venuePath}
          className='px-12 lg:p-0 justify-center'
        />
      </div>
    </div>
  );
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [{ title: makeTitle(data?.pack.name ?? '') }];
};

export const Component = PublicGamePackPage;
export default Component;
